<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    ...mapActions(["mobileHandling"]),
    handleResize() {
      if (window.innerWidth < 640) {
        this.isMobile = true;
        this.mobileHandling(this.isMobile);
      } else {
        this.isMobile = false;
        this.mobileHandling(this.isMobile);
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>