import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isMobile: false
    },
    mutations: {
        SET_ISMOBILE(state, payload) {
            state.isMobile = payload
        }
    },
    actions: {
        mobileHandling({ commit }, statement) {
            commit('SET_ISMOBILE', statement)
        }
    },
    modules: {}
})